<template>
  <div id="login-page" class="d-flex justify-content-center align-items-center">
    <div class="d-flex align-items-center flex-column p-3">
      <img
        src="@/assets/img/logos/logo_branco.png"
        class="img-logo"
        alt="Mestres do Valor"
      />
      <h4 class="text-white text-center mt-4 fw-normal">
        INSIRA SEU CPF PARA COMEÇAR A JOGAR
      </h4>
      <div class="w-100">
        <input
          id="cpf"
          type="text"
          class="form-control form-control-lg text-center md-wd"
          maxlength="11"
          v-model="cpf"
          placeholder="Digite apenas os números do seu CPF"
        />
        <div v-if="isShowError" class="text-center">
          <span class="text-warning fs-xs"
            >Atenção, CPF deve conter 11 caracteres, foram informados
            {{ cpf.length }} caracteres.</span
          >
        </div>
        <button
          type="button"
          @click="auth"
          class="btn btn-primary btn-lg rounded-pill mt-3 md-wd"
          :disabled="disableButton"
        >
          JOGAR
        </button>
      </div>
      <div class="mt-2">
        <a
          href="https://gruposn.com.br/mestredovalor-regras/"
          class="text-center text-white mt-4"
          >REGULAMENTO</a
        >
      </div>
      <div class="text-center mt-2">
        <button
          type="button"
          class="btn btn-success rounded-pill text-center"
          title="Login"
          @click="clickCallback"
        >
          <span>Instalar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Auth',
  data() {
    return {
      deferredPrompt: null,
      isLoading: false,
      isShowError: false,
      disableButton: true,
      cpf: '',
      backgroundUrl: '~@/assets/img/backgrounds/background_blue.jpg',
    }
  },
  components: {},
  methods: {
    captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
      })
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // Add analyticcs event
          this.$gtag.event('add_to_home_screen')
        }
        this.deferredPrompt = null
      })
    },
    async auth() {
      try {
        this.isLoading = true
        await this.$store.dispatch('auth', this.cpf)
        this.$router.push(PATHS.HOME)
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  watch: {
    cpf() {
      if (this.cpf.length === 11) {
        this.disableButton = false
        this.isShowError = false
      }
      if (this.cpf.length < 11 && this.cpf.length > 0) {
        this.disableButton = true
        this.isShowError = true
      }
    },
  },
  mounted() {
    this.captureEvent()
    if (localStorage.getItem('sessionError')) {
      this.$root.$refs.notification.warning(
        localStorage.getItem('sessionError'),
      )
      localStorage.removeItem('sessionError')
    }
  },
}
</script>
<style scoped>
#login-page {
  height: 100vh;
  width: 100vw;
  margin: -1.25rem;
  background-image: url('~@/assets/img/backgrounds/background_blue.png');
}
.img-logo {
  width: 200px;
}
</style>
