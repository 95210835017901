<template>
  <slot />
</template>

<script>
import Noty from 'noty'

export default {
  name: 'Notification',

  methods: {
    success(text) {
      this.notification(text, 'success')
    },

    warning(text) {
      this.notification(text, 'warning')
    },

    error(text) {
      this.notification(text, 'error')
    },

    info(text) {
      this.notification(text, 'info')
    },

    notification(text, type) {
      Noty.overrideDefaults({
        theme: 'limitless',
        layout: 'topRight',
        type: 'alert',
        timeout: 2500,
      })

      new Noty({
        text,
        type,
      }).show()
    },
  },
}
</script>
