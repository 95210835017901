import api from '@/services/api'

export default {
  state: {
    isLoggedInMestresDoValor: !!localStorage.getItem('tokenMestresDoValor'),
    user: {
      cpf: null,
      name: null,
      showPhoto: null,
      codColigada: null,
      codFilial: null,
      filialName: null,
    },
  },
  mutations: {
    AUTH(state, user) {
      state.isLoggedInMestresDoValor = true
      state.user = user
    },

    LOGOUT(state) {
      state.isLoggedInMestresDoValor = false
      state.user = {
        cpf: null,
        name: null,
        showPhoto: null,
        codColigada: null,
        codFilial: null,
        filialName: null,
      }
    },
  },
  actions: {
    async auth({ commit }, credentials) {
      try {
        const response = await api.post('/auth/create-token-colaborator', {
          cpf: credentials,
        })
        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''
        const user = data && data.user ? data.user : ''
        user.cpf = credentials
        localStorage.setItem('tokenMestresDoValor', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },
    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },

  getters: {
    isLoggedInMestresDoValor: (state) => state.isLoggedInMestresDoValor,
    user: (state) => state.user,
    userPhoto: (state) => state.user.showPhoto,
  },
}
