import home from './modules/home'
import group from './modules/group'
import question from './modules/question'
import profile from './modules/profile'
import achievement from './modules/achievement'

export default {
  home,
  group,
  question,
  profile,
  achievement,
}
