/* eslint-disable no-unused-expressions */
/* eslint-disable no-new */
/* eslint-disable no-restricted-syntax */
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'

window.bootstrap = bootstrap

// Setup module
// ------------------------------
const App = (function () {
  //
  // Detect OS to apply custom scrollbars
  //

  // Custom scrollbar style is controlled by CSS. This function is needed to keep default
  // scrollbars on MacOS and avoid usage of extra JS libraries
  const detectOS = function () {
    const { platform } = window.navigator
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const customScrollbarsClass = 'custom-scrollbars'

    // Add class if OS is windows
    if (windowsPlatforms.indexOf(platform) !== -1)
      document.documentElement.classList.add(customScrollbarsClass)
  }

  //
  // Transitions
  //

  // Disable all transitions
  const transitionsDisabled = function () {
    document.body.classList.add('no-transitions')
  }

  // Enable all transitions
  const transitionsEnabled = function () {
    document.body.classList.remove('no-transitions')
  }

  // Components
  // -------------------------

  // Tooltip
  const componentTooltip = function () {
    const tooltipSelector = document.querySelectorAll(
      '[data-bs-popup="tooltip"]',
    )

    tooltipSelector.forEach(function (popup) {
      new bootstrap.Tooltip(popup, {
        boundary: '.page-content',
      })
    })
  }

  // Popover
  const componentPopover = function () {
    const popoverSelector = document.querySelectorAll(
      '[data-bs-popup="popover"]',
    )

    popoverSelector.forEach(function (popup) {
      new bootstrap.Popover(popup, {
        boundary: '.page-content',
      })
    })
  }

  // "Go to top" button
  const componentToTopButton = function () {
    // Elements
    const toTopContainer = document.querySelector('.content-wrapper')
    const toTopElement = document.createElement('button')
    const toTopElementIcon = document.createElement('i')
    const toTopButtonContainer = document.createElement('div')
    const toTopButtonColorClass = 'btn-secondary'
    const toTopButtonIconClass = 'ph-arrow-up'
    const scrollableContainer = document.querySelector('.content-inner')
    const scrollableDistance = 250
    const footerContainer = document.querySelector('.navbar-footer')

    // Append only if container exists
    if (scrollableContainer) {
      // Create button container
      toTopContainer.appendChild(toTopButtonContainer)
      toTopButtonContainer.classList.add('btn-to-top')

      // Create button
      toTopElement.classList.add(
        'btn',
        toTopButtonColorClass,
        'btn-icon',
        'rounded-pill',
      )
      toTopElement.setAttribute('type', 'button')
      toTopButtonContainer.appendChild(toTopElement)
      toTopElementIcon.classList.add(toTopButtonIconClass)
      toTopElement.appendChild(toTopElementIcon)

      // Show and hide on scroll
      const toTopButton = document.querySelector('.btn-to-top')
      const addClassOnScroll = () =>
        toTopButton.classList.add('btn-to-top-visible')
      const removeClassOnScroll = () =>
        toTopButton.classList.remove('btn-to-top-visible')

      scrollableContainer.addEventListener('scroll', function () {
        const scrollpos = scrollableContainer.scrollTop
        scrollpos >= scrollableDistance
          ? addClassOnScroll()
          : removeClassOnScroll()
        if (footerContainer) {
          if (
            this.scrollHeight - this.scrollTop - this.clientHeight <=
            footerContainer.clientHeight
          ) {
            toTopButton.style.bottom = `${footerContainer.clientHeight + 20}px`
          } else {
            toTopButton.removeAttribute('style')
          }
        }
      })

      // Scroll to top on click
      document
        .querySelector('.btn-to-top .btn')
        .addEventListener('click', function () {
          scrollableContainer.scrollTo(0, 0)
        })
    }
  }

  // Misc
  // -------------------------

  // Dropdown submenus. Trigger on click
  const dropdownSubmenu = function () {
    // Classes
    const menuClass = 'dropdown-menu'
    const submenuClass = 'dropdown-submenu'
    const menuToggleClass = 'dropdown-toggle'
    const disabledClass = 'disabled'
    const showClass = 'show'

    if (submenuClass) {
      // Toggle submenus on all levels
      document
        .querySelectorAll(
          `.${menuClass} .${submenuClass}:not(.${disabledClass}) .${menuToggleClass}`,
        )
        .forEach(function (link) {
          link.addEventListener('click', function (e) {
            e.stopPropagation()
            e.preventDefault()

            // Toggle classes
            link.closest(`.${submenuClass}`).classList.toggle(showClass)
            link
              .closest(`.${submenuClass}`)
              .querySelectorAll(`:scope > .${menuClass}`)
              .forEach(function (children) {
                children.classList.toggle(showClass)
              })

            // When submenu is shown, hide others in all siblings
            for (const sibling of link.parentNode.parentNode.children) {
              if (sibling !== link.parentNode) {
                sibling.classList.remove(showClass)
                sibling
                  .querySelectorAll(`.${showClass}`)
                  .forEach(function (submenu) {
                    submenu.classList.remove(showClass)
                  })
              }
            }
          })
        })

      // Hide all levels when parent dropdown is closed
      document.querySelectorAll(`.${menuClass}`).forEach(function (link) {
        if (!link.parentElement.classList.contains(submenuClass)) {
          link.parentElement.addEventListener(
            'hidden.bs.dropdown',
            function (e) {
              link
                .querySelectorAll(`.${menuClass}.${showClass}`)
                .forEach(function (children) {
                  children.classList.remove(showClass)
                })
            },
          )
        }
      })
    }
  }

  //
  // Return objects assigned to module
  //

  return {
    // Disable transitions before page is fully loaded
    initBeforeLoad() {
      detectOS()
      transitionsDisabled()
    },

    // Initialize all components
    initComponents() {
      componentTooltip()
      componentPopover()
      componentToTopButton()
    },

    // Dropdown submenu
    initDropdowns() {
      dropdownSubmenu()
    },

    // Initialize core
    initCore() {
      App.initBeforeLoad()
      App.initComponents()
      App.initDropdowns()
    },

    // Enable transitions when page is fully loaded
    initAfterLoad() {
      transitionsEnabled()
    },
  }
})()

// Initialize module
// ------------------------------

// When content is loaded
document.addEventListener('DOMContentLoaded', function () {
  App.initCore()
})

// When page is fully loaded
window.addEventListener('load', function () {
  App.initAfterLoad()
})
